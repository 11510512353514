import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { listSitePaymentMethodTypes } from '@wix/ambassador-payments-site-payment-method-types-v1-site-payment-method-type/http';

import type {
  ListSitePaymentMethodTypesResponse,
  ListSitePaymentMethodTypesRequest,
} from '@wix/ambassador-payments-site-payment-method-types-v1-site-payment-method-type/types';

export const fetchListSitePaymentMethodTypes = async (
  httpClient: IHttpClient,
  payload: ListSitePaymentMethodTypesRequest,
): Promise<ListSitePaymentMethodTypesResponse> => {
  const { data } = await httpClient.request(
    listSitePaymentMethodTypes(payload),
  );

  return data;
};
