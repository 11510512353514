import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import type { SitePaymentMethodType } from '@wix/ambassador-payments-site-payment-method-types-v1-site-payment-method-type/types';
import type { Experiments } from '@wix/yoshi-flow-editor';
import type {
  PaymentMethodMeta,
  SavedPaymentMethod,
  SavedPaymentMethodWithMeta,
} from '@appTypes';
import {
  getPaymentMethodMetaData,
  type PaymentMethodBrand,
} from '@utils/metaData';
import { DEFAULT_PM_TITLE, Experiment } from '@constants';
import ccIconURI from '@assets/icons/creditCard.svg?url';

export const getPaymentMethodBrand = (
  paymentMethod: SavedPaymentMethod['paymentMethod'],
): PaymentMethodBrand | undefined => {
  if (paymentMethod.typeId === PaymentMethod.CreditCard) {
    return paymentMethod?.cardInfo?.brand;
  }

  if (paymentMethod.typeId === PaymentMethod.PayPal) {
    return PaymentMethod.PayPal;
  }
};

export const concatWithMetaData = (
  savedPaymentMethods: SavedPaymentMethod[],
  experiments: Experiments,
): SavedPaymentMethodWithMeta[] => {
  const methodsWithMeta: SavedPaymentMethodWithMeta[] = [];

  savedPaymentMethods.forEach((savedPaymentMethod) => {
    const { paymentMethod } = savedPaymentMethod;

    if (paymentMethod) {
      const { cardInfo, accountInfo, ...paymentMethodDetails } = paymentMethod;
      const paymentMethodBrand = getPaymentMethodBrand(paymentMethod);

      const paymentMethodMeta = experiments.enabled(
        Experiment.UseSitePaymentMethodTypes,
      )
        ? {
            displayName: '',
            image: {
              url: null,
            },
          }
        : getPaymentMethodMetaData(paymentMethodBrand);

      methodsWithMeta.push({
        ...savedPaymentMethod,
        ...paymentMethodMeta,
        paymentMethod: {
          ...paymentMethodDetails,
          // @ts-expect-error TODO fix after discussion
          //  https://wix.slack.com/archives/CFRR5LLAE/p1725614296101079?thread_ts=1722605830.020839&cid=CFRR5LLAE
          paymentInfo: cardInfo || accountInfo,
        },
      });
    }
  });

  return methodsWithMeta;
};

export function getPaymentMethodUIMetaData(
  paymentMethods?: SitePaymentMethodType[],
): PaymentMethodMeta[] | undefined {
  return paymentMethods?.map(({ id, displayName, buyerFlow }) => {
    const svgUrl =
      buyerFlow?.icons?.find((icon) => icon.format === 'SVG')?.image?.url ??
      ccIconURI;

    return {
      id: id ?? '',
      displayName: displayName ?? '',
      image: {
        url: svgUrl,
      },
    };
  });
}
export const concatWithServiceMetaTypes = (
  savedPaymentMethods: SavedPaymentMethodWithMeta[],
  sitePaymentMethodTypes: SitePaymentMethodType[] | undefined,
): SavedPaymentMethodWithMeta[] => {
  if (!sitePaymentMethodTypes) {
    return savedPaymentMethods;
  }

  const metaDataArray = getPaymentMethodUIMetaData(sitePaymentMethodTypes);

  if (!metaDataArray) {
    return savedPaymentMethods;
  }

  return savedPaymentMethods
    .filter(({ paymentMethod }) => paymentMethod)
    .map((savedPaymentMethod) => {
      const { paymentMethod } = savedPaymentMethod;
      const metaData = metaDataArray.find(
        ({ id }) =>
          id === paymentMethod?.paymentMethodBrandId ||
          id === paymentMethod?.paymentMethodTypeId,
      );

      if (metaData) {
        return {
          ...savedPaymentMethod,
          displayName: metaData.displayName,
          image: {
            url: metaData.image.url,
          },
        };
      }

      return savedPaymentMethod;
    });
};

export const concatWithDefaultMetaTypes = (
  savedPaymentMethods?: SavedPaymentMethodWithMeta[],
): SavedPaymentMethodWithMeta[] | undefined => {
  if (!savedPaymentMethods) {
    return;
  }
  return savedPaymentMethods.map((savedPaymentMethod) => {
    return {
      ...savedPaymentMethod,
      displayName: DEFAULT_PM_TITLE,
      image: {
        url: ccIconURI,
      },
    };
  });
};
