import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { SitePaymentMethodType } from '@wix/ambassador-payments-site-payment-method-types-v1-site-payment-method-type/types';
import { fetchListSitePaymentMethodTypes } from './http';

export class PaymentMethodTypesService {
  constructor(private readonly httpClient: IHttpClient) {}

  async getSitePaymentMethodTypes(
    ids: string[],
  ): Promise<SitePaymentMethodType[] | undefined> {
    const { sitePaymentMethodTypes } = await fetchListSitePaymentMethodTypes(
      this.httpClient,
      {
        ids,
      },
    );

    return sitePaymentMethodTypes;
  }
}
