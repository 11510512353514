import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { AppSetProps, ControllerProps } from '@appTypes';
import { Modal, Experiment } from '@constants';
import {
  getAccountId,
  getAppIdentityParams,
  getSiteCurrency,
} from '@utils/app';
import type { DemoView } from '@settings/constants';
import type { SettingsEventManager } from '@services/SettingsEventManager';
import { WarmupData } from '@services/WarmupData';
import { PaymentMethodService } from '@services/api/PaymentMethod';
import { SavedPaymentMethodService } from '@services/api/SavedPaymentMethod';
import { PaymentMethodTypesService } from '@services/api/SitePaymentMethodTypes';
import { appActions } from '@infrastructure/redux/app';
import { modalsActions } from '@infrastructure/redux/modal';
import { toastsActions } from '@infrastructure/redux/toast';
import { getIsRecurringSupported } from '@infrastructure/redux/paymentMethod';
import {
  fetchSavedPaymentMethods,
  markSavedPaymentMethodPrimary,
  removeSavedPaymentMethod,
  savedPaymentMethodsActions,
  fetchPaymentMethodTypes,
} from '@infrastructure/redux/savedPaymentMethod';
import settingsParams from '../settingsParams';
import { initializeBi } from './initializeBi';
import { initializeStore } from './initializeStore';

export const initializeWidget = async (
  controllerParams: ControllerParams,
  settingsEventManager: SettingsEventManager,
) => {
  const { controllerConfig, flowAPI } = controllerParams;
  const { wixCodeApi } = controllerConfig;
  const { httpClient, environment, errorMonitor, experiments } = flowAPI;
  const appParams = getAppIdentityParams(controllerConfig);
  const setProps = controllerConfig.setProps as AppSetProps;

  errorMonitor.addBreadcrumb({
    data: appParams,
  });

  const warmupData = new WarmupData(flowAPI);
  const paymentMethodsApi = new PaymentMethodService(httpClient);
  const paymentMethodsListApi = new PaymentMethodTypesService(httpClient);
  const savedPaymentMethodsApi = new SavedPaymentMethodService(httpClient);
  const { store, stateToProps } = initializeStore(controllerParams, {
    warmupData,
    paymentMethodsApi,
    paymentMethodsListApi,
    savedPaymentMethodsApi,
  });
  const biLogger = initializeBi(controllerParams, store);

  const getAppSettings = (): void => {
    store.dispatch(getIsRecurringSupported(getAccountId(appParams)));
  };

  const openModal: ControllerProps['modalActions']['openModal'] = (
    currentModal,
  ) => {
    store.dispatch(modalsActions.openModal(currentModal));
  };

  const closeModal: ControllerProps['modalActions']['closeModal'] = () => {
    store.dispatch(modalsActions.closeModal());
  };

  const showToast: ControllerProps['toastActions']['showToast'] = (
    text,
    meta,
  ) => {
    store.dispatch(toastsActions.showToast({ text, meta }));
  };

  const closeToast: ControllerProps['toastActions']['closeToast'] = () => {
    store.dispatch(toastsActions.closeToast());
  };

  const getSavedPaymentMethods = async (demoView?: DemoView): Promise<void> => {
    await store.dispatch(fetchSavedPaymentMethods({ demoView }));

    if (experiments.enabled(Experiment.UseSitePaymentMethodTypes)) {
      store.dispatch(fetchPaymentMethodTypes());
    }
  };

  const onRemove: ControllerProps['onRemove'] = (paymentMethodId) => {
    store.dispatch(
      savedPaymentMethodsActions.setManagedMethodId(paymentMethodId),
    );
    openModal(Modal.ConfirmPaymentMethodRemoval);
  };

  const onRemovalSubmit: ControllerProps['onRemovalSubmit'] = (
    paymentMethodId,
  ) => {
    store.dispatch(removeSavedPaymentMethod({ paymentMethodId }));
  };

  const onMarkPrimary: ControllerProps['onMarkPrimary'] = (paymentMethodId) => {
    store.dispatch(markSavedPaymentMethodPrimary({ paymentMethodId }));
  };

  const onDataFetchRetry: ControllerProps['onDataFetchRetry'] = () => {
    store.dispatch(appActions.clearError());
    getSavedPaymentMethods();
  };

  getAppSettings();
  getSavedPaymentMethods(flowAPI.settings.get(settingsParams.demoView));

  if (environment.isEditor) {
    settingsEventManager.onDemoViewChanged(getSavedPaymentMethods);
  }

  setProps({
    biLogger,
    appParams,
    ...stateToProps(),
    fitToContentHeight: true,
    siteCurrency: getSiteCurrency(wixCodeApi),
    modalActions: { openModal, closeModal },
    toastActions: { showToast, closeToast },
    onAddNewSubmit: getSavedPaymentMethods,
    onMarkPrimary,
    onRemove,
    onRemovalSubmit,
    onDataFetchRetry,
  });
};
